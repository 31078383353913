import React from 'react';

import { array, bool, func, number, object } from 'prop-types';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import './StackedCards.scoped.scss';

StackedCards.propTypes = {
  listOffres: array,
  idOffreActivated: number,
  setOffreId: func,
  isInViewGamme: bool,
  isNosFonds: bool,
  handleMouseDown: func,
  handleMouseMove: func,
  dragOffsetX: number,
  refCards: object
};

export function StackedCards({
  listOffres,
  idOffreActivated,
  setOffreId,
  isInViewGamme,
  isNosFonds,
  dragOffsetX,
  refCards
}) {
  const renderCard = (idx, offre) => {
    return (
      <Card
        isInViewGamme={isInViewGamme}
        key={idx}
        idx={idx}
        offre={offre}
        setOffreId={setOffreId}
        idOffreActivated={idOffreActivated}
        isNosFonds={isNosFonds}
      />
    );
  };

  const renderCards = () => {
    const cards = [];
    listOffres.forEach((offre, index) => cards.unshift(renderCard(index, offre)));
    return cards;
  };

  return (
    <div
      ref={refCards}
      style={{
        transform: `translate(${dragOffsetX}px, 0)`,
        display: 'flex',
        transitionDuration: '250ms',
        width: '34.79166666666667vw',
        height: '450px'
      }}
      className="cards">
      {renderCards()}
    </div>
  );
}

Card.propTypes = {
  idx: number,
  offset: number,
  offre: object,
  setOffreId: func,
  idOffreActivated: number,
  isInViewGamme: bool,
  isNosFonds: bool
};

export const fiche = [
  {
    id: 1,
    urlNote:
      'https://www.afgassetmanagement.com/admin/uploads/AFG_CASH_MANAGEMENT_NOTE_DINFORMATION_d8ea2b207d.pdf',
    urlFiche:
      'https://www.afgassetmanagement.com/admin/uploads/AFG_CASH_MANAGEMENT_FICHE_SIGNALETIQUE_4c8aabcff1.pdf',
    titre: 'Monétaire',
    sheetName: 'AFG CASH'
  },
  {
    id: 2,
    urlNote:
      'https://www.afgassetmanagement.com/admin/uploads/AFG_LIQUIDITY_FUND_NOTE_DINFORMATION_5e289de625.pdf',
    urlFiche:
      'https://www.afgassetmanagement.com/admin/uploads/AFG_LIQUIDITY_FUND_FICHE_SIGNALETIQUE_1111dfeaef.pdf',
    titre: 'OCT',
    sheetName: 'AFG LIQUIDITY'
  },
  {
    id: 3,
    urlNote:
      'https://www.afgassetmanagement.com/admin/uploads/AFG_INCOME_FUND_NOTE_DINFORMATION_a060dd9b26.pdf',
    urlFiche:
      'https://www.afgassetmanagement.com/admin/uploads/AFG_INCOME_FUND_FICHE_SIGNALETIQUE_6be6e45fc2.pdf',
    titre: 'OMLT',
    sheetName: 'AFG INCOME'
  },
  {
    id: 4,
    urlNote:
      'https://www.afgassetmanagement.com/admin/uploads/AFG_GOV_BOND_FUND_NOTE_D_INFORMATION_127047021b.pdf',
    urlFiche:
      'https://www.afgassetmanagement.com/admin/uploads/AFG_GOV_BOND_FUND_FICHE_SIGNALETIQUE_a28eadd541.pdf',
    titre: 'OMLT',
    sheetName: 'AFG GOV BOND'
  },
  {
    id: 5,
    urlNote:
      'https://www.afgassetmanagement.com/admin/uploads/AFG_OPTMAL_FUND_NOTE_INFOTMATION_6c98c0b3ba.pdf',
    urlFiche:
      'https://www.afgassetmanagement.com/admin/uploads/AFG_OPTMAL_FUND_FICHE_SIGNALETIQUE_c12e8fa1a9.pdf',
    titre: 'Diversifiée',
    sheetName: 'AFG OPTIMAL'
  },
  {
    id: 6,
    urlNote:
      'https://www.afgassetmanagement.com/admin/uploads/AFG_DYNAMIC_FUND_NOTE_DINFORMATION_0f1b4161d6.pdf',
    urlFiche:
      'https://www.afgassetmanagement.com/admin/uploads/AFG_DYNAMIC_FUND_FICHE_SIGNALETIQUE_8a340dd9b9.pdf',
    titre: 'Actions',
    sheetName: 'AFG DYNAMIC'
  }
];

function Card({ idx, offre, setOffreId, idOffreActivated, isInViewGamme, isNosFonds }) {
  const className = 'card';
  const diff = isNosFonds ? 0 : offre.id - idOffreActivated;
  let transformProp = `unset`;

  if (isNosFonds) {
    transformProp = 'translate(' + idx * 700 + 'px, 0px)';
  } else {
    transformProp =
      isInViewGamme && !isNosFonds
        ? 'translate(0, 0)'
        : 'translate(' + -(5 - idx) * 40 + 'px, ' + -(5 - idx) * 40 + 'px)';
  }

  const newOffset = diff === 0 ? 0 : diff;
  const style = {
    transform: transformProp,
    top: `${newOffset}vh`,
    left: `${newOffset}vw`,
    backgroundColor: offre.backgroundColor,
    transitionDuration: '1s',
    zIndex: offre.id >= idOffreActivated ? 1 : 0,
    opacity: isNosFonds || offre.id >= idOffreActivated ? 1 : 0
  };
  const moveForward = () => {
    if (idOffreActivated === 5) {
      setOffreId(0);
    } else {
      setOffreId(idOffreActivated + 1);
    }
  };

  const moveBackWard = () => {
    if (idOffreActivated === 0) {
      setOffreId(5);
    } else {
      setOffreId(idOffreActivated - 1);
    }
  };

  const navigate = useNavigate();
  const onClickAfgMonetaire = (id) => navigate('/notre-gamme/' + id);

  const onClickDownloadPdf = (Filename) => {
    fetch(Filename, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf'
      }
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', Filename.slice(48));

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  return (
    <div key={idx} className={className} style={style} data-idx={idx}>
      {!isNosFonds && (
        <div className="icons-container">
          <div className="icon-back">
            <IconButton
              sx={{
                borderRadius: '50%',
                borderStyle: 'solid',
                borderWidth: '1px',
                color: offre.textColor
              }}
              color="primary"
              aria-label="upload picture"
              component="label"
              onClick={moveBackWard}>
              <ArrowBackIosNewIcon />
            </IconButton>
          </div>
          <div className="icon-forward">
            <IconButton
              sx={{
                borderRadius: '50%',
                borderStyle: 'solid',
                borderWidth: '1px',
                color: offre.textColor
              }}
              color="primary"
              aria-label="upload picture"
              component="label"
              onClick={moveForward}>
              <ArrowForwardIosIcon />
            </IconButton>
          </div>
        </div>
      )}
      <div style={{ color: offre.textColor }} className="classification-frequence-container">
        <div className="labels-classification-frequence-container">
          <div className="classificiation">Classification</div>
          <div className="frequence">Fréquence VL</div>
        </div>
        <div
          style={{
            borderLeft: `1px solid ${offre.textColor}`,
            height: '5.37037037037037vh'
          }}
          className="divider"
        />
        <div className="values-classification-frequence-container">
          <div className="classificiation">{offre.classification}</div>
          <div className="frequence">Quotidienne</div>
        </div>
      </div>
      <div style={{ color: offre.textColor }} className="card-large-title">
        <div onClick={() => onClickAfgMonetaire(offre?.idPage)} className="card-large-title-text">
          {offre.titreCarte}
        </div>
      </div>
      <div className="divider-larg-title" />
      <div style={{ color: offre.textColor }} className="paragraph-stacked-card">
        {offre.text}
      </div>
      <div
        style={{
          color: offre.textColor
        }}
        className="telechargements-container">
        <div
          onClick={() => onClickDownloadPdf(fiche?.find((el) => el.id === offre?.idPage).urlNote)}
          className="telechargement">
          <FileDownloadIcon />
          <div>Note d&apos;information</div>
        </div>
        <div
          onClick={() => onClickDownloadPdf(fiche?.find((el) => el.id === offre?.idPage).urlFiche)}
          className="telechargement">
          <FileDownloadIcon />
          <div>Fiche signalétique</div>
        </div>
        <div
          onClick={() => onClickAfgMonetaire(offre?.idPage)}
          style={{ border: `1px solid ${offre.textColor}`, color: offre.textColor }}
          className="btn-ensavoir-plus">
          En savoir plus
        </div>
        {idx === 0 && (
        <div style={{
          fontSize: `13px`
        }}>
         (*) Etablissements et entreprises publiques
        </div>
      )}
      </div>
    </div>
  );
}
